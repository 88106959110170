import { render, staticRenderFns } from "./CustomerEditForm.vue?vue&type=template&id=b13afc46&scoped=true&"
import script from "./CustomerEditForm.vue?vue&type=script&lang=js&"
export * from "./CustomerEditForm.vue?vue&type=script&lang=js&"
import style0 from "./CustomerEditForm.vue?vue&type=style&index=0&id=b13afc46&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b13afc46",
  null
  
)

export default component.exports